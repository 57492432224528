import React from "react"
import Seo from "../components/seo"
import styled, { css } from "styled-components"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export const servicesQuery = graphql`
  {
    scope: contentfulScope {
      scopeItems {
        id
        title
        desc {
          desc
        }
        image {
          gatsbyImageData(placeholder: BLURRED, formats: PNG)
        }
      }
    }
  }
`

export default function Services({ data: { scope } }) {
  return (
    <>
      <Seo title="Spectrum Of Services" />
      <ServicesPage>
        {scope.scopeItems.map(item => (
          <Card key={item.id}>
            <Front>
              <GatsbyImage
                image={item.image.gatsbyImageData}
                alt={`${item.title} lighting design image`}
                style={{ width: "100%" }}
                placeholder="BLURRED"
              />
              <h3>{item.title}</h3>
            </Front>
            <Back>
              <p>{item.desc.desc}</p>
            </Back>
          </Card>
        ))}
      </ServicesPage>
    </>
  )
}

const ServicesPage = styled.div`
  margin: 5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media screen and (max-width: 1150px) {
    margin: 5rem 5vw;
  }
`

const FlipStyle = css`
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 0.5s;
  transition-property: transform, opacity;
`

const Front = styled.div`
  ${FlipStyle}
  transform: rotateY(0deg);
  backface-visibility: hidden;
  img {
    height: 35rem;
    border-radius: 5px;
    z-index: -1;
    background: rgba(0, 0, 0, 0.4);
  }

  h3 {
    padding: 1rem 2rem;
    text-align: center;
    font-weight: 400;
    position: absolute;
    top: 29rem;
    left: 2rem;
    color: #fff;
  }
`
const Back = styled.div`
  ${FlipStyle}
  position: absolute;
  opacity: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: rotateY(-180deg);
  background: #000;
  border-radius: 5px;
  display: flex;
  align-items: center;
  p {
    line-height: 1.5;
    font-size: 1.8rem;
    color: #fff;
    padding: 4rem;
  }
`
const Card = styled.div`
  flex: 0 0 calc(33.33% - 1.5rem);
  margin-right: 2rem;
  margin-bottom: 2rem;
  transform-style: preserve-3d;
  position: relative;
  &:nth-child(3n) {
    margin-right: 0;
  }

  &:hover {
    ${Front} {
      transform: rotateY(180deg);
    }
    ${Back} {
      opacity: 1;
      transform: rotateY(0deg);
    }
  }
  @media screen and (max-width: 880px) {
    flex: 0 0 calc(50% - 1.5rem);
    &:nth-child(3n) {
      margin-right: 2rem;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 480px) {
    flex: 0 0 100%;
  }
`
